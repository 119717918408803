import React from "react";
import { graphql } from "gatsby";
import Page from "../../components/Page";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Fotografering({ data }) {
  const image = data.image.childImageSharp.gatsbyImageData;

  const title = "Tjäna pengar på att sälja bilder (Guide)"

  return (
    <>
      <Page title={title} description="Om du är en duktig fotograf så finns det en stor möjlighet att tjäna pengar på din hobby. Genom att ladda upp dina bilder kan du börja tjäna en slant redan idag.">
        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="fotografera" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>


            <p>Om du är en duktig fotograf så finns det en stor möjlighet att tjäna pengar på din hobby. Genom att ladda upp dina bilder kan du börja tjäna en slant redan idag.</p>
            <p>Det flera sidor som du använda dig av för att sälja din bilder på nätet, såsom <b>Shutterstock, Istockphoto, Adobe Stock och Getty Images.</b></p>
            <h2>Hur kan man sälja foton på nätet?</h2>
            <p>Om du vill börja med att sälja dina foton på nätet så är det vanligast att vända sig till en bildbyrå.</p>
            <p>En bildbyrå kan ses som en bilddatabas som förmedlar bilder mellan fotografer och köpare. Det finns många tänkbara köpare som du säljer fotografier online, såsom tidningar, webbplatser, för annonser, bloggar och så vidare.</p>
            <p>Att använda sig av en bildbyrå för att sälja bilder är ett gammalt koncept som funnits mycket länge, men tidigare var det bara proffsfotograferna som hade tillgänglighet. Det är först nu när plattformarna blivit digitala som även amatörer, som mig själv och du, fått en chans att konkurrera mot proffsen och sälja bilder.</p>
            <p>När du säljer en bild på nätet får du betalt för varje nedladdad bild, och ersättningen ligger vanligtvis mellan 2 till 300 kronor. Genom att köpa din bild kommer köparen därefter att ha laglig rätt till att använda din bild.</p>
            <p>För att sälja bilder online finns det flera sidor som du kan använda dig av, nedan listar jag några exempel:</p>
            <ul>
              <li>Shutterstock</li>
              <li>Istockphoto</li>
              <li>Adobe Stock</li>
              <li>Getty Images</li>
            </ul>
            <p>Ersättningen från dessa olika stocksidor eller digitala bildbyråer varierar och därav är det ett brett spann i ersättningen. Ofta tar även dessa sidor en stor andel av försäljningen, och du kan förvänta dig att få omkring 50 procent av intäkterna för en såld bild.</p>
            <p>Processen för att börja sälja bilder på någon av dessa stocksidorna ser ofta ut enligt följande:</p>
            <ol>
              <li>Registrera dig på plattformen</li>
              <li>Ladda upp bilderna du vill tjäna pengar på</li>
              <li>Lägg till nyckelord och en beskrivning för varje bild</li>
              <li>Skicka in bilderna för granskning</li>
              <li>När bilderna blivit accepterade kan du sedan skicka in fler</li>
            </ol>
            <p>På vissa av sidorna, såsom Adobe Stock, kommer du också behöva att legitimera dig genom att skicka en bild på ditt körkort för att få börja tjäna pengar.</p>
            <h3>Vad är viktigt att tänka på när du säljer foton?</h3>
            <p>Det finns några saker som man bör tänka på när man säljer foton på nätet. När dina bilder säljs för användning i ett kommersiellt sammanhang, så behöver du ha tillstånd från personer, privata områden, logotyper som syns i bilden.</p>
            <p>Om du ska sälja ett foto på nätet behöver du därmed kolla upp så att du har ett tillstånd att publicera innehållet innan du säljer det.</p>

            <h2>Vad är ersättningen per nedladdad bild?</h2>
            <p>Tidigare nämnde vi att ersättningen ligger mellan 2 till 300 kronor. Hur mycket kan man tjäna från varje sida per såld bild på de olika stocksidorna?</p>
            <p>På fototjänsten Shutterstock kan du tjäna mellan <b>2 till 27 kronor</b> per såld bild. Kravet här för att få sälja bilder är att du måste skicka in 10 bilder, varav minst sju måste godkännas.</p>
            <p>På iStock kan du förvänta dig en inkomst per såld bild på <b>18 till 23 kronor</b>. Kravet här är att du måste skicka in minsta tre bilder på 1,9 megapixel eller mer. Bilderna måste sedan godkännas.</p>
            <p>På Adobe Stock kan du säljer genom fotolia.com och där kan du förvänta dig en inkomst mellan <b>2 till 31 kronor</b> per såld bild.</p>
            <p>På Getty Images kan du tjäna mellan <b>30 till 300 kronor</b> per såld bild. Här säljs bilderna däremot till en mycket högre pris och du kommer därför snabbt märka att du kommer sälja färre bilder här jämfört med exempelvis Shutterstock.</p>
            <p>På många av dessa sidor beror också inkomsten per bild på antalet bilder som du har sålt totalt. När du säljer fler bilder på en av sidorna kan du förvänta dig en ökad inkomst med tiden.</p>

            <h2>Hur mycket tjänar man på att sälja foton?</h2>
            <p>Hur mycket du tjänar beror till stor del på hur många foton du har laddat upp. Om du laddat upp fler bilder kommer du tjäna mer.</p>
            <p>Deltidsarbete har tidigare skrivit en artikel där de uppgav att de tjänade <a href="https://deltidsarbete.net/arbete/fotografering_tjana_pengar_foton" target="_blank" rel="nofollow">72 920 kronor</a> per år genom totalt 370 publicerade bilder online, så det går att skapa en stor passiv inkomster från att sälja foton på nätet.</p>
            <p>Om du skulle publicera en ny bild på bilddatabaserna varje dag skulle du i slutet av året har 365 foton på nätet som kan generera dig en passiv inkomst. Vissa av dessa foton kommer troligtvis inte ge dig så mycket pengar, medan andra kommer ge dig betydligt mer.</p>
            <p>Säg att du kan tjäna omkring 100 till 200 kronor per bild per år. Då skulle du tjäna mellan 36 500 till 73 000 kronor årligen om du publicerat 365 bilder, vilket är väldigt mycket pengar i passiva inkomster.</p>

            <h2>Hur kan man öka chanserna att bilderna säljs?</h2>
            <p>För att sälja foton har man i de flesta fallen varit tvungen att vara otroligt duktig tidigare, och den konstnärliga aspekten har varit mycket viktig. När man säljer stockfoton är det däremot viktigare att sälja foton som köparen efterfrågar.</p>
            <p>Försöka att hitta en bildkategori där konkurrensen på stocksajterna inte är så hög, samtidigt som det är en kategori som är intressanta för företag och privatpersoner. Bilder som innehåller personer och där någon gör något, såsom en sport, brukar vanligtvis sälja rätt så bra.</p>
            <p>För att öka dina inkomster så bör du också publicera fler bilder, för då kommer du ha större chans att du publicerat ett foto som en köpare letar efter. Om du vill börja sälja foton på nätet bör du bygga upp en bred bildportfölj.</p>
            <br />
            <br />
          </article>
        </div>
      </Page>
    </>
  );
}



export const query = graphql`
  query {
    image: file(relativePath: { eq: "salja-foton.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
